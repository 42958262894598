import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'

import './footer.scss'

import {
  cityPath, PARIS_CITY_PATH,
  CONTACT_PATH, LEGAL_PATH,
  alphabeticBarPath, ALPHABETIC_LETTERS,
} from '../path'


const AlphabeticPages = () => (
  <div className="content has-text-centered alphabetic">
    <p>
      <span>
        Bars commençant par :
      </span>
      {_.map(ALPHABETIC_LETTERS, (letter) => (
        <span className="letter" key={letter}>
          <Link to={alphabeticBarPath(letter)}>{letter}</Link>
        </span>
      ))}
    </p>
  </div>
)

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="columns">
        <div className="column">
          <Link to={PARIS_CITY_PATH}>
            Bars les moins chers de Paris
          </Link>
          <br />
          <Link to={cityPath({ name: 'Lyon' })}>
            Bars les moins chers de Lyon
          </Link>
          <br />
          <Link to={cityPath({ name: 'Marseille' })}>
            Bars les moins chers de Marseille
          </Link>
        </div>
        <div className="column">
          <Link to={cityPath({ name: 'Bordeaux' })}>
            Bars les moins chers de Bordeaux
          </Link>
          <br />
          <Link to={cityPath({ name: 'Toulouse' })}>
            Bars les moins chers de Toulouse
          </Link>
          <br />
          <Link to={cityPath({ name: 'Lille' })}>
            Bars les moins chers de Lille
          </Link>
        </div>
        <div className="column">
          <Link to={cityPath({ name: 'Nantes' })}>
            Bars les moins chers de Nantes
          </Link>
          <br />
          <Link to={cityPath({ name: 'Strasbourg' })}>
            Bars les moins chers de Strasbourg
          </Link>
          <br />
          <Link to={cityPath({ name: 'Montpellier' })}>
            Bars les moins chers de Montpellier
          </Link>
        </div>
        <div className="column">
          <Link to="/blog">
            Blog
          </Link>
          <br />
          <Link to={CONTACT_PATH}>
            Contact
          </Link>
          <br />
          <Link to={LEGAL_PATH}>
            Mentions légales
          </Link>
        </div>
      </div>

      <AlphabeticPages />

      <div className="content has-text-centered">
        <p>
          <strong>
            MisterGoodBeer.
          </strong>
          {' Fighting for a better beer world.'}
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
