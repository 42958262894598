import React from 'react'
import { Link } from 'gatsby'
import { BEST_BARS_PATH, PARIS_CITY_PATH, cityPath } from '../path'

import './style.scss'

const CITIES_IN_MENU = [
  'Paris', 'Lyon', 'Bordeaux', 'Marseille', 'Toulouse', 'Lille', 'Nantes',
  'Strasbourg', 'Montpellier',
]

const Menu = ({ onClick }) => (
  <div className="navbar-start" onClick={onClick} role="presentation">
    <Link to="/" className="navbar-item clickable-navbar-item">
      Accueil
    </Link>
    <div className="navbar-item has-dropdown is-hoverable">
      <Link to={BEST_BARS_PATH} className="navbar-link clickable-navbar-item">
        Meilleurs Bars de France
      </Link>
      <div className="navbar-dropdown is-boxed">
        {
          CITIES_IN_MENU.map((city) => (
            <Link
              className="navbar-item clickable-navbar-dropdown-item"
              to={city === 'Paris' ? PARIS_CITY_PATH : cityPath({ name: city })}
            >
              {`Meilleurs Bars de ${city}`}
            </Link>
          ))
        }
      </div>
    </div>
    <Link to="/blog" className="navbar-item clickable-navbar-item">
      Blog
    </Link>
  </div>
)

export default Menu
