import React from 'react'
import { Helmet } from 'react-helmet'

import './index.scss'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import {
  fbAppId,
  logoPath,
  siteMetadata,
  twitterAccount,
  twitterUserId,
} from '../config'

const defaultImageAlt = 'MisterGoodBeer, le site pour trouver les bars pas chers qui servent les meilleures bières'

const SEO = ({
  url = siteMetadata.siteUrl,
  title = siteMetadata.title,
  keywords = 'MisterGoodBeer bar bières pas cher',
  description = siteMetadata.description,
  contentType = 'website',
  image = logoPath,
  imageAlt = defaultImageAlt,
  locale = 'fr',
  schemaOrgJSONLD,
}) => {
  const fullUrl = url.startsWith('http') ? url : `${siteMetadata.siteUrl}${url}`
  const imageFullUrl = image.startsWith('http') ? image : `${siteMetadata.siteUrl}${image}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={contentType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageFullUrl} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image:alt" content={imageAlt} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterAccount} />
      <meta name="twitter:site:id" content={twitterUserId} />
      <meta name="twitter:creator" content={twitterAccount} />
      <meta name="twitter:creator:id" content={twitterUserId} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageFullUrl} />
      <meta name="twitter:image:alt" content={imageAlt} />

      {/* Facebook tags */}
      <meta property="fb:app_id" content={fbAppId} />

      {/* Schema.org tags */}
      {schemaOrgJSONLD
        ? <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
        : undefined}
    </Helmet>
  )
}

const Template = ({ children, seoProps, withFooter = true }) => (
  <main>
    <SEO {...seoProps} />
    <Navbar />
    {children}
    {withFooter ? <Footer /> : <div />}
  </main>
)

export default Template
