import React from 'react'
import { Link } from 'gatsby'
import {
  barPath, cityPath, departmentPath, regionPath,
} from '../path'


const BreadCrumbElement = ({
  linkTo, label, isActive, position,
}) => (
  <li
    itemProp="itemListElement"
    itemScope
    itemType="http://schema.org/ListItem"
    className={isActive ? 'is-active' : ''}
  >
    <Link to={linkTo} itemProp="item">
      <span itemProp="name">{label}</span>
    </Link>
    <meta itemProp="position" content={position} />
  </li>
)

export default ({
  region, department, cityName, bar,
}) => (
  <nav
    className="breadcrumb"
    aria-label="breadcrumbs"
    role="navigation"
    itemScope
    itemType="http://schema.org/Breadcrumb"
  >
    <ol itemScope itemType="http://schema.org/BreadcrumbList">
      <BreadCrumbElement
        linkTo="/"
        label="Accueil"
        position="1"
      />
      {region
        ? (
          <BreadCrumbElement
            linkTo={regionPath(region)}
            label={region.name}
            isActive={!department}
            position="2"
          />
        ) : undefined}
      {department
        ? (
          <BreadCrumbElement
            linkTo={departmentPath(department)}
            label={department.name}
            isActive={!cityName}
            position="3"
          />
        ) : undefined}
      {cityName
        ? (
          <BreadCrumbElement
            linkTo={cityPath({ name: cityName })}
            label={`Bars à ${cityName}`}
            isActive={!bar}
            position="4"
          />
        ) : undefined}
      {bar
        ? (
          <BreadCrumbElement
            linkTo={barPath(bar.slug)}
            label={bar.name}
            isActive
            position="5"
          />
        ) : undefined}
    </ol>
  </nav>
)
