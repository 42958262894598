const siteMetadata = {
  siteName: 'MisterGoodBeer',
  title: 'MisterGoodBeer',
  description: 'Les meilleurs bars, les bières pas chères',
  siteUrl: 'https://www.mistergoodbeer.com',
}

module.exports = {
  siteMetadata,
  logoPath: `${siteMetadata.siteUrl}/img/mistergoodbeer-logo.png`,
  fbAppId: '407712259289916',
  twitterAccount: '@mistergoodbeer',
  twitterUserId: '906517303',
}
