const moment = require('moment')
// Set locale to french for dates
require('moment/locale/fr')

const isMobile = () => window.innerWidth <= 760

const formatTime = (date) => moment.utc(date.iso).locale('fr').format('LT')

module.exports = {
  isMobile,
  formatTime,
}
