import React from 'react'
import { Link } from 'gatsby'
import { appBarPath, barPath } from '../path'
import { formatTime } from '../tools'

const BarTile = (bar) => {
  const {
    name,
    address,
    regularPrice,
    happyHourPrice,
    startHappyHour,
    endHappyHour,
    slug,
  } = bar
  const barHasHappyHour = happyHourPrice
    && (!regularPrice || happyHourPrice < regularPrice)

  return (
    <div className="column is-6">
      <div className="module-card card">
        <header className="card-header">
          <h2 className="card-header-title">
            <Link to={barPath(slug)} style={{ flexGrow: 1 }}>
              {name}
            </Link>
          </h2>
        </header>
        <div className="card-content">
          <h3 className="subtitle is-6">
            {address}
          </h3>
          <p>
            {barHasHappyHour && !regularPrice
              ? `Pinte à ${happyHourPrice}€ de ${formatTime(startHappyHour)} à ${formatTime(endHappyHour)}`
              : null}
            {barHasHappyHour && regularPrice
              ? `Pinte à ${happyHourPrice}€ de ${formatTime(startHappyHour)} à ${formatTime(endHappyHour)} (${regularPrice}€ hors happy hour)`
              : null}
            {!barHasHappyHour && regularPrice
              ? `Pinte à ${regularPrice}€`
              : null}
          </p>
        </div>
        <footer className="card-footer">
          <p className="card-footer-item">
            <a href={appBarPath(bar)}>
              Voir sur la carte MisterGoodBeer
            </a>
          </p>
        </footer>
      </div>
    </div>
  )
}

export default BarTile
