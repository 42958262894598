const LEGAL_PATH = '/legal'
const CONTACT_PATH = '/contact'
const BAR_PATH = '/bars'
const BAR_PER_CITY_PATH = '/bars-pas-chers'
const BEST_BARS_PATH = '/meilleurs-bars-france'
const ALPHABETIC_BARS_PATH = '/top-des-bars-en-'
const PARIS_METRO_PATH = '/top-des-bars'

const PARIS_CITY_PATH = '/top-bars-sympa-paris'
const METRO_PARIS_PATH = '/top-bars-sympa-paris-metro'

const slugify = (string) => (
  string
    .toLowerCase()
    .replace(/\s+/g, '-') // - instead of spaces
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // remove accents
    .replace(/[^\w-]+/g, '') // keep only words
    .replace(/--+/g, '-') // no consecutive -
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end
)

const appBarPath = (bar) => `/app/map/${bar.id}`
const barPath = (barSlug) => `${BAR_PATH}/${barSlug}`
const bookBarPath = (bar) => (
  bar.privateaser_booking_url ? `${BAR_PATH}/${bar.slug}/book` : undefined
)
const regionId = (region) => `${slugify(region.name)}`
const regionPath = (region) => `${BEST_BARS_PATH}/#${regionId((region))}`

const departmentPath = (department) => (
  `${BEST_BARS_PATH}/${slugify(department.name)}-${department.code}`
)

const cityPath = (city) => `${BAR_PER_CITY_PATH}/${slugify(city.name)}`

const parisMetroPath = (metro) => `${PARIS_METRO_PATH}-${slugify(metro.name)}-paris`

const alphabeticBarPath = (letter) => `${ALPHABETIC_BARS_PATH}${letter}`

const buildAlphabeticLetters = () => {
  const letters = []
  for (let i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i += 1) {
    letters.push(String.fromCharCode(i))
  }
  return letters
}

const ALPHABETIC_LETTERS = buildAlphabeticLetters()

module.exports = {
  slugify,
  appBarPath,
  barPath,
  bookBarPath,
  cityPath,
  LEGAL_PATH,
  CONTACT_PATH,
  BEST_BARS_PATH,
  regionId,
  regionPath,
  departmentPath,
  parisMetroPath,
  alphabeticBarPath,
  ALPHABETIC_LETTERS,
  PARIS_CITY_PATH,
  METRO_PARIS_PATH,
}
