import _ from 'lodash'
import React from 'react'
import { graphql } from 'gatsby'

import BarTile from '../components/BarTile'
import BarTable from '../components/BarTable'

import './style.scss'
import Layout from '../layouts'
import LocalityBreadCrumb from '../components/LocalityBreadCrumb'
import { appBarPath, barPath, bookBarPath } from '../path'

const N_NEARBY_BARS = 6

const barSelector = (bars) => _.sampleSize(bars, N_NEARBY_BARS)

const getCity = (data, pathContext) => {
  if (pathContext.country === 'France') {
    if (data.cityPostalCode) return data.cityPostalCode
    if (data.cityByName) return data.cityByName
  }
  return undefined
}


const barCheapestPrice = (bar) => (
  bar.happyHourPrice ? bar.happyHourPrice : bar.regularPrice
)

const metaDescription = (bar, city) => (
  `${bar.name}, le bar qui sert des pintes à ${barCheapestPrice(bar)}€\
  ${city ? `à ${city.name}` : ''}.\
  Retrouvez tous les détails sur MisterGoodBeer.`
)

const metaKeywords = (bar, city) => (
  `${bar.name} pinte bière pas cher MisterGoodBeer\
  ${city ? `${city.name} ${city.department.name} ${city.region.name}` : ''}`
)

const schemaOrgJSONLD = (bar) => ([
  {
    '@context': 'http://schema.org',
    '@type': 'BarOrPub',
    url: `https://www.mistergoodbeer.com${barPath(bar.slug)}`,
    name: bar.name,
    address: bar.address,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: bar.location.latitude,
      longitude: bar.location.longitude,
    },
    hasMap: `https://www.mistergoodbeer.com${appBarPath(bar)}`,
    priceRange: barCheapestPrice(bar) < 6 ? '$' : '$$',
    // image is required so we put the logo...`
    image: 'https://www.mistergoodbeer.com/img/mistergoodbeer-logo.png',
  },
])

const BarPageTemplate = ({ data, pathContext }) => {
  const { mainBar, nearbyBars } = data

  // const selectedNearbyBars = barSelector(nearbyBars.edges)
  const selectedNearbyBars = nearbyBars ? barSelector(nearbyBars.edges) : []

  const city = getCity(data, pathContext)

  return (
    <Layout
      seoProps={{
        url: `${barPath(mainBar.slug)}}`,
        title: `${mainBar.name} | Bar pas cher | MisterGoodBeer`,
        description: metaDescription(mainBar, city),
        keywords: metaKeywords(mainBar, city),
        schemaOrgJSONLD: schemaOrgJSONLD(mainBar),
      }}
    >
      <section className="section">
        <div className="container">
          {city
            ? (
              <LocalityBreadCrumb
                region={city.region}
                department={city.department}
                cityName={city.name}
                bar={mainBar}
              />
            ) : undefined}

          <h1 className="title is-1">
            {city
              ? `${mainBar.name}, bar pas cher à ${city.name}`
              : `${mainBar.name}, bar pas cher`}
          </h1>
          <div className="container">
            {<BarTable {...mainBar} />}
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <a href={appBarPath(mainBar)} className="button is-link">
                    Voir sur la carte MisterGoodBeer
                  </a>
                </div>
                {bookBarPath(mainBar) ? (
                  <div className="level-item">
                    <a href={bookBarPath(mainBar)} className="button is-link">
                    Réserver pour un groupe
                    </a>
                  </div>
                ) : <div />}
              </div>
            </div>
          </div>
          <hr />
          <h2 className="title is-2">
            Bars qui claquent dans le même coin
          </h2>
          <div className="columns is-multiline">
            { selectedNearbyBars
              .map((barNode) => <BarTile {...barNode.node} key={barNode.node.id} />)}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BarPageTemplate

export const pageQuery = graphql`
  query BarBySlug(
    $id: String!,
    $slug: String!,
    $lowerLatitude: Float!,
    $upperLatitude: Float!,
    $lowerLongitude: Float!,
    $upperLongitude: Float!,
    $cityName: String,
    $cityPostalCode: String,
  ) {
    mainBar: misterGoodBeerBar(
      slug: { eq: $slug }
    ) {
      id
      slug
      name
      address
      address_details {
        vicinity
      }
      location {
        latitude
        longitude
      }
      regularPrice
      happyHourPrice
      startHappyHour { iso }
      endHappyHour { iso }
      privateaser_booking_url
    }

    nearbyBars: allMisterGoodBeerBar(
      filter: {
        active: { eq: true },
        location: {
          latitude: { gt: $lowerLatitude, lt: $upperLatitude},
          longitude: { gt: $lowerLongitude, lt: $upperLongitude},
        },
        id: { ne: $id },
      }
    ) {
      edges {
        node {
          id
          slug
          name
          address
          address_details {
            vicinity
          }
          regularPrice
          happyHourPrice
          startHappyHour { iso }
          endHappyHour { iso }
        }
      }
    }
    cityByName: citiesJson(name: {eq: $cityName}) {
      ...cityFragment
    }
    cityByPostalCode: citiesJson(postalCode: {eq: $cityPostalCode}) {
      ...cityFragment
    }
  }

  fragment cityFragment on CitiesJson {
    name
    postalCode
    department {
      name
      code
    }
    region {
      name
      code
    }
  }
`
