import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Menu from './Menu'

const BurgerIcon = ({ onClick, isActive }) => {
  const isActiveClass = isActive
    ? 'is-active' : null
  return (
    <div
      className={`navbar-burger burger ${isActiveClass}`}
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
    >
      {/* Theses span are used for the burger's bars */}
      <span />
      <span />
      <span />
    </div>
  )
}

const MGBLogo = () => (
  <StaticQuery
    query={graphql`
      query {
        mgbLogo: file(relativePath: { eq: "img/mistergoodbeer-logo.png" }) {
          childImageSharp {
            fixed(height: 54) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <Link className="navbar-item" to="/">
        <Img
          fixed={data.mgbLogo.childImageSharp.fixed}
          alt="MisterGoodBeer - L'application des bieres pas chères avec ou sans happy hour"
        />
      </Link>
    )}
  />
)

const CallToAction = () => (
  <div className="navbar-end">
    <div className="navbar-item">
      <div className="field is-grouped">
        <p className="control">
          <a className="button is-primary" href="/app/">
            <span>
              Voir la carte des bars
            </span>
          </a>
        </p>
      </div>
    </div>
  </div>
)

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this)
    this.closeBurgerMenu = this.closeBurgerMenu.bind(this)
    this.state = {
      isBurgerMenuActive: false,
    }
  }

  toggleBurgerMenu() {
    this.setState((prevState) => ({
      isBurgerMenuActive: !prevState.isBurgerMenuActive,
    }))
  }

  closeBurgerMenu() {
    this.setState({ isBurgerMenuActive: false })
  }

  render() {
    const { isBurgerMenuActive } = this.state
    const isActiveMenuClass = isBurgerMenuActive ? 'is-active' : null

    return (
      <nav className="navbar is-fixed-top has-shadow">
        <div className="navbar-brand">
          <MGBLogo />
          <BurgerIcon
            onClick={this.toggleBurgerMenu}
            isActive={isBurgerMenuActive}
          />
        </div>
        <div className={`navbar-menu ${isActiveMenuClass}`}>
          <Menu onClick={this.closeBurgerMenu} />
          <CallToAction />
        </div>
      </nav>
    )
  }
}

export default Navbar
