import React from 'react'
import { formatTime } from '../tools'

const BarTable = (bar) => {
  const {
    name,
    address,
    regularPrice,
    happyHourPrice,
    startHappyHour,
    endHappyHour,
  } = bar

  const barHasHappyHour = happyHourPrice
    && (!regularPrice || happyHourPrice < regularPrice)

  const getFields = () => {
    let fields = [
      ['Nom', name],
      ['Adresse', address],
    ]

    if (regularPrice) {
      fields = [
        ...fields,
        ['Prix hors happy hour', `${regularPrice}€`],
      ]
    }

    if (barHasHappyHour) {
      fields = [
        ...fields,
        ['Prix en happy hour', `${happyHourPrice}€`],
        ['Début de l\'happy hour', formatTime(startHappyHour)],
        ['Fin de l\'happy hour', formatTime(endHappyHour)],
      ]
    }

    return fields
  }

  const BarFieldRow = ({ label, value }) => (
    <tr>
      <td>
        {label}
      </td>
      <td>
        {value}
      </td>
    </tr>
  )

  return (
    <table className="table is-striped is-fullwidth">
      <tbody>
        {getFields().map((field) => {
          const [label, value] = field
          return <BarFieldRow label={label} value={value} key={label} />
        })}
      </tbody>
    </table>
  )
}

export default BarTable
